@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

/* Import Roboto Slab for Greek gonts */

body {
  margin: 0;
  font-family: 'Roboto Slab';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #b3ebf2; /* pastel blue */
  background: #808080; /* pastel blue */
  background: white; /* white */
  color: hsl(209, 61%, 16%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  letter-spacing:  0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h2 {
  color: #989D85
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

h5 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 2rem 0;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1170px){
.copyrights {
  margin-bottom: 2rem;
  color: hsl(210, 22%, 49%);
  display: flex;
  text-align: center;
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;
  justify-content: center;
}
}

@media screen and (min-width: 1170px){
  .copyrights {
    margin-bottom: 2rem;
    color: hsl(210, 22%, 49%);
    display: flex;
    text-align: center;
    grid-gap: 1rem 1rem;
    gap: 1rem 1rem;
    justify-content: center;
  }
  }

.underline {
  width: 6rem;
  height: 0.15rem;
  background: #FADBC7;
  margin-left: auto;
  margin-right: auto;
}

.underline_end {
  width: auto;
  height: 0.15rem;
  background: #FADBC7;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.filter-btn {
  background:  transparent;
  border-color: #FADBC7;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 2.2rem 1rem;
  /*color:  hsl(360, 71%, 66%);*/
  color:  black;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 5.5rem;
  font-family: 'Roboto Slab';
  width: 100px; /* all circles in button the same*/
}

.active {
  border-bottom: 2px solid;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  grid-gap: 3rem 2rem;
  gap: 3rem 2rem;
  justify-items: center;
}

.menu-item {
  display: grid;
  grid-gap: 1rem 2rem;
  gap: 1rem 2rem;
  max-width: 25rem;
  color: black;
}

.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  /*border: 0.25rem solid hsl(360, 71%, 66%);*/
  border: none;
  border-radius: 0.25rem;
  display: block;
}

.logo {
  object-fit: cover;
  height: auto;
  width: 150px;
  border-radius: 0.25rem;  
}

.cube-logo {
  object-fit: cover;
  height: auto;
  width: 50px;
  border-radius: 0.25rem;  
}

.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted hsl(210, 22%, 49%);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color:  #FADBC7;
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    grid-gap: 0 1.25rem;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}

@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}
